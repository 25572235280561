<script>
  import { _ } from 'svelte-i18n'
  import Loader from '../../components/Loader.svelte'
  import { getData } from '../../utils/ApiUtils.svelte'
  import { onMount } from 'svelte'
  import RainbowDays from './RainbowDays.svelte'
  import RainboxIconSvg from './RainboxIconSvg.svelte'

  let courseList = []
  let dayStreak

  let courseStatus = 'loading'

  onMount(async () => {
    getDashboardData()
  })

  function isSameDay(d1, d2) {
    return (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getDate() === d2.getDate() &&
      d1.getMonth() === d2.getMonth()
    )
  }

  function getDashboardData() {
    getData('studentapi/dashboard/recording-statistics?lastXDays=7')
      .then((data) => {
        console.log('course successful', data)

        courseList = data
        courseStatus = 'ok'
        dayStreak = 0

        var curr = new Date()

        let shift = curr.getDay() - 1
        if (shift === -1) {
          shift = 6
        }

        var firstday = new Date(curr.setDate(curr.getDate() - shift))
        // var lastday = new Date(curr.setDate(curr.getDate() - curr.getDay()+6));

        let z = [...Array(7).keys()].map((index) => {
          const date = new Date(firstday)
          date.setDate(date.getDate() + index)

          let dateValue = date.toISOString().substring(0, 10)
          let count = courseList.filter((x) => x.date === dateValue)

          if (count?.length > 0) {
            dayStreak++
          }

          let v = {
            d: dateValue,
            isToday: isSameDay(new Date(), date),
            v: count?.length > 0 ? count[0].count : 0,
          }
          return v
        })

        courseList = z
        console.log(444, z)
      })
      .catch((error) => {
        courseStatus = 'error'
        console.log('error', error)
      })
  }
  let x = 14

  let y = [2, 4, 5, 6, 8, 1, 2, 6, 7, 8]
  let roygbiv = [
    '#DA4038',
    '#E37D42',
    '#F4D965',
    '#549E6F',
    '#82AAD3',
    '#3554EE',
    '#4A2A74',
  ]

  function formatDate(v) {
    var options = {
      weekday: 'short',
      day: 'numeric',
    }
    var day = new Date(Date.parse(v.d))

    console.log(day.toLocaleDateString('en-US', options)) // Saturday, September 17, 2016
    return (
      day.toLocaleDateString('en-US', {
        weekday: 'short',
      }) +
      ' ' +
      day.toLocaleDateString('en-US', {
        day: 'numeric',
      })
    )
  }
</script>

<div class="wrapper mt-3 mb-3">
  {#if courseStatus === 'loading'}
    <Loader />{:else if courseStatus === 'ok'}
    <!-- <div class="col-12 .s-streak">
    <span style="font-weight: 400;">Streak:</span>
    <span style="font-weight: 700;"> Day {dayStreak}</span>
  </div> -->

    <div class="col-auto">
      <RainbowDays {courseList} />
      <!-- <div class="s-progress-frame">
    {#each courseList as d, i}
      {#if d?.v > 0}<div
          class="s-progress-item"
          style={`background-color: hsl(${-(360 * i) / y.length} ,80%,50%)`}
        >
          {i + 1} 
        </div>{:else}<div
          class="s-progress-item"
          style={`background-color: hsl(${-(360 * i) / y.length} ,80%,15%)`}
        >
          {i + 1}
        </div>{/if}
    {/each}
  </div> -->

      <!-- <div class="s-progress-frame">
      {#each courseList as d, i}
        {#if d?.v > 0}<div
            class="s-progress-item"
            style={`background-color: ${roygbiv[i]}`}
          />{:else}<div
            class="s-progress-item"
            style={`background-color: grey`}
          />{/if}
      {/each}
    </div>
    <div class="s-progress-frame">
      {#each courseList as d, i}
        <div class="s-progress-date">{formatDate(d)}</div>
      {/each}
    </div> -->
    </div>
    <div class="col-auto">
      <RainboxIconSvg {courseList} />
    </div>
  {/if}
</div>

<style>
  .wrapper {
    display: flex;
  }
  @media only screen and (max-device-width: 660px) {
    .wrapper {
      justify-content: center;
    }
  }

  .s-streak {
    font-size: large;
  }

  .s-progress-item {
    /* padding: 1em; */
    border: 1px;
    border-style: solid;
    border-color: black;
    min-height: 30px;
    min-width: 30px;
  }

  .s-progress-date {
    border: 1px;
    border-style: solid;
    border-color: black;
    text-align: center;
  }

  .s-progress-frame {
    border-color: black;
    border-radius: 3px;
    border-style: solid;
    /* display: flex; */
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
</style>
